import ConnectivityIssues from './ConnectivityIssues'
import TryAgain from './TryAgain'

import { useRouteErrorWithReporter } from '~/hooks/useRouteErrorWithReporter'

const BaseErrorPage = () => {
  const error = useRouteErrorWithReporter()
  console.error(error)

  if (error instanceof Error && error.message === 'Failed to fetch') {
    return <ConnectivityIssues />
  }

  return <TryAgain />
}

export default BaseErrorPage
