import { useNavigate } from '@remix-run/react'

const useRefreshCache = () => {
  const navigate = useNavigate()
  const refreshCache = (route?: string) =>
    navigate(`/hard-refresh?route=${route}`, { replace: true })
  return refreshCache
}

export default useRefreshCache
